const toggleText = () => {
  try {
    const trigger = document.querySelectorAll('#toggle-text'),
      text = document.querySelectorAll('.toggle-text'),
      textP = document.querySelectorAll('.toggle-text p')


    textP.forEach((item, i) => {
      const textHeight = +window.getComputedStyle(item).height.replace('px', '')
      if (textHeight < 150) {
        trigger[i].style.display = 'none'
      }
    })

    trigger.forEach((item, i) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()

        if (text[i].classList.contains('hide')) {
          item.textContent = 'Скрыть'
        } else {
          item.textContent = 'Подробнее'
        }

        text[i].classList.toggle('hide')
      })
    })
  } catch (e) {}
}

export default toggleText;