import {
  gsap
} from "gsap";
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const myGsap = () => {

  function gsapPlayer() {
    const tl = gsap.timeline()

    let onceAutoPlay = true

    tl.fromTo('#btn-all', {
      x: '5%',
      y: '53%',
    }, {
      x: '5%',
      y: '0%'
    })
    tl.to('.player-layer', {
      borderBottomWidth: '0vh'
    })

    ScrollTrigger.create({
      animation: tl,
      trigger: '.player',
      start: 'top center',
      end: 'bottom bottom+=120px',
      scrub: true,
      onLeave: () => autoplay(),
      markers: false
    })

    function autoplay() {
      const video = document.querySelector("#video");
      video.play();
    }
  }

  try {
    gsapPlayer()
  } catch (e) {}

  function parallax() {
    const tl = gsap.timeline()

    const screenHeight = window.getComputedStyle(document.querySelector('.screen-main')).height.replace('px', '')

    tl.to('.screen-main', {
      y: `800px`
    })

    ScrollTrigger.create({
      animation: tl,
      trigger: '.ascents',
      start: 'top top',
      end: `bottom top+=${screenHeight / 2}`,
      scrub: true
    })
  }

  try {
    parallax()
  } catch (e) {}

  function guides() {
    const tl = gsap.timeline()

    const wrapperWidth = window.getComputedStyle(document.querySelector('.competitors-guides')).width.replace('px', ''),
      wrapperW = window.getComputedStyle(document.querySelector('.competitors-wrapper')).width.replace('px', '')

    tl.set('.competitors__else-guides', {
      opacity: 0,
      visibility: 'hidden'
    })
    tl.set('.competitors-guides__item-info', {
      opacity: 0,
      visibility: 'hidden'
    })

    tl.to('.competitors-info', {
      opacity: 0,
      visibility: 'hidden'
    })

    tl.from('#first-guid', {
      x: `${wrapperW - 450}px`,
      scale: 0.8,
      rotate: '-10deg',
      duration: 20
    }, 0)
    tl.from('#second-guid', {
      x: `${wrapperW - 570}px`,
      y: '-70px',
      scale: 0.6,
      rotate: '5deg',
      duration: 20
    }, 1)
    tl.from('#third-guid', {
      x: `${wrapperW - 1100}px`,
      y: '250px',
      scale: 0.6,
      rotate: '-10deg',
      duration: 20
    }, 1)
    tl.from('#fourth-guid', {
      x: `${wrapperW - 1200}px`,
      y: '210px',
      scale: 0.8,
      rotate: '5deg',
      duration: 20
    }, 1)

    tl.to('.competitors__else-guides', {
      opacity: 1,
      visibility: 'visible',
      duration: 20
    })
    tl.to('.competitors-guides__item-info', {
      opacity: 1,
      visibility: 'visible',
      duration: 20
    }, 5)

    tl.to('.competitors-guides', {
      x: `-${wrapperWidth - wrapperW}px`,
      duration: 50
    })

    ScrollTrigger.create({
      animation: tl,
      trigger: '.competitors',
      //start: 'center center',
      end: `bottom+=${wrapperWidth} top`,
      scrub: true,
      pin: '.competitors-guides',
      markers: false
    })
  }

  try {

    if (window.matchMedia("(min-width: 993px)").matches) {
      guides()
    }
  } catch (e) {}

  gsap.utils.toArray(".about-guides__wrapper").forEach(section => {
    const containerWidth = section.offsetWidth;
    const slides = section.querySelectorAll(".about__persons-item");

    const sliderGsap = slides.forEach(slide => {
      const leftBounds = -(slide.offsetWidth + slide.offsetLeft);
      const wrapFunc = gsap.utils.unitize(
        gsap.utils.wrap(
          leftBounds,
          containerWidth + leftBounds
        )
      );

      gsap.to(slide, {
        x: -containerWidth,
        ease: "none",
        delay: .4,
        duration: 50,
        repeat: -1,
        modifiers: {
          x: wrapFunc
        },
      });
    });
  });
}

export default myGsap;