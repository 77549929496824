const slick = () => {
  try {
    $('.footer-upper').slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      appendArrows: '.footer-upper',
      prevArrow: '<a href="#" class="button-footer button-footer__left"><svg><use href="#arrow-left"></use></svg></a>',
      nextArrow: '<a href="#" class="button-footer button-footer__right"><svg><use href="#arrow-left"></use></svg></a>',
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 772,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    });

    $('.home-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      prevArrow: $('.button-slider__left'),
      nextArrow: $('.button-slider__right'),
      arrows: true,
    });

    $('.about-reviews__slider').slick({
      centerMode: true,
      centerPadding: '30%',
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [{
          breakpoint: 992,
          settings: {
            centerPadding: '20%',
          }
        },
        {
          breakpoint: 576,
          settings: {
            centerMode: false,
            slidesToShow: 1
          }
        }
      ]
    });


  } catch (e) {}
}

export default slick;