const submenu = () => {
  try {
    const humburger = document.querySelector('.humburger'),
      submenu = document.querySelector('.header-nav'),
      submenuParents = document.querySelectorAll('.header-nav__parent'),
      submenuChilds = document.querySelectorAll('.header-nav__child'),
      submenuBack = document.querySelectorAll('.header-nav__child-back')

    humburger.addEventListener('click', () => {
      submenu.classList.toggle('nav-active')
      humburger.classList.toggle('humburger-active')
    })

    submenuParents.forEach((item, i) => {
      if (window.matchMedia("(max-width: 992px)").matches) {
        item.addEventListener('click', (e) => {
          e.preventDefault()

          submenuChilds[i].classList.add('child-active')
        })
      }
    })

    submenuBack.forEach((item, i) => {
      item.addEventListener('click', () => {
        closeChild(i)
      })
    })

    function closeChild(i) {
      submenuChilds[i].classList.remove('child-active')
    }
  } catch (e) {}
}

export default submenu;