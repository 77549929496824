const fancyBoxSlider = () => {
  try {
    function slider(sliderSelector) {
      const myCarousel = new Carousel(document.querySelector(`${sliderSelector}`), {
        preload: 2,
        infinite: false,
        slidesPerPage: 1,
        center: true
      });
    }

    slider('#myCarouselElbrus')
    slider('#myCarouselKilimanjaro')
    slider('#myCarouselAnnapurna')
  } catch (e) {}
}

export default fancyBoxSlider