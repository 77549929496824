import fancyBoxSlider from "./modules/fancyBoxSlider";
import myGsap from "./modules/myGsap";
import slick from "./modules/slick";
import submenu from "./modules/submenu";
import toggleText from "./modules/toggleText";
import getGallery from "./modules/getGallery";
import qa from "./modules/qa";

window.addEventListener("DOMContentLoaded", () => {
  if (window.matchMedia("(max-width: 1200px)").matches) {
    fancyBoxSlider();
  }
  getGallery();
  myGsap();
  slick();
  toggleText();
  submenu();
  qa();

  $(function () {
    const div = document.querySelector(".backside-overflow");
    const maxScrollTop = document.body.clientHeight - window.innerHeight;

    window.addEventListener("scroll", () => {
      try {
        div.style.opacity = 0.5 - window.pageYOffset / maxScrollTop;
      } catch (e) {}
    });
  });

  try {
    const buttonScrollTop = document.getElementById('buttonTop')

    buttonScrollTop.addEventListener('click', function (e) {
      e.preventDefault()
      goToUp()
    });

    function goToUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const scroll = new SmoothScroll({
      // Время скролла 400 = 0.4 секунды
      animationTime: 600,
      // Размер шага в пикселях
      stepSize: 80,

      // Дополнительные настройки:

      // Ускорение
      accelerationDelta: 40,
      // Максимальное ускорение
      accelerationMax: 2,

      // Поддержка клавиатуры
      keyboardSupport: true,
      // Шаг скролла стрелками на клавиатуре в пикселях
      arrowScroll: 50,

      // Pulse (less tweakable)
      // ratio of "tail" to "acceleration"
      pulseAlgorithm: true,
      pulseScale: 4,
      pulseNormalize: 1,

      // Поддержка тачпада

      touchpadSupport: true,
    });
  } catch (e) {}


  try {

    $(document).on('click', '[data-copy-order-item]', function () {
      var items = $('[data-copy-order-items]'),
        item = items.find('[data-copy-order-item-target]').first().clone(),
        count = items.find('.order-item').length;
      item.find('input').val('');
      item.find('input').each(function () {
        $(this).attr('name', $(this).attr('name').replace('[0]', '[' + count + ']'));
      })
      item.removeAttr('data-copy-order-item-target');
      item.find('.is-mask-init').removeClass('is-mask-init');
      item.find('.flag').attr('class', 'flag');
      items.append(item);
      calculateOrderForm();
      return false;
    })

    $(document).on('click', '[data-remove-order-item]', function () {
      $(this).closest('.order-item').remove();
      calculateOrderForm();
      return false;
    })

    function calculateOrderForm() {
      var items = $('[data-copy-order-items]'),
        priceItem = $('[data-order-price]'),
        price = parseFloat(priceItem.attr('data-order-price')),
        count = items.find('.order-item').length;
      priceItem.text(new Intl.NumberFormat('ru-RU').format((price * count)));
      var count = items.find('.order-item').length;
      var k = 0;
      items.find('.order-item').each(function () {
        k++;
        if (!$(this).attr('data-copy-order-item-target')) {
          var textItem = $(this).find('[data-text-template]');
          textItem.text(textItem.attr('data-text-template').replace('#', k))
        }
      })
    }

    $(document).on('change', '[data-validate] .is-invalid', function (e) {
      $(this).removeClass('is-invalid');
    })
    $(document).on('submit', '[data-validate]', function (e) {
      var btn = $(this).find('[type="submit"]')
      if (!checkForm(this)) {
        e.preventDefault();
      } else {
        $.ajax({
          type: "POST",
          url: "/assets/ajax.php",
          data: $(this).serialize(),
          success: function (data) {
            window.location.href = '/spasibo-za-zayavku.html';
          },
          error: function (data) {
            console.log(data)
          }
        });
        e.preventDefault();
      }

    })

    function checkForm(form, container) {
      var noErrors = true,
        errors = [];
      if (!container || container.length == 0) {
        container = $(form)
      }
      if (container.find('[data-error-place]').length > 0) {
        container.find('[data-error-place]').empty();
      }
      container.find('[data-check], .required').each(function () {
        var input = $(this);
        var errorText = input.data('error') || 'РќРµРѕР±С…РѕРґРёРјРѕ Р·Р°РїРѕР»РЅРёС‚СЊ СЌС‚Рѕ РїРѕР»Рµ';
        var error = false;
        input.each(function () {
          var inp = $(this);
          inp.parent().removeClass('error-label form__label--error');
          inp.removeClass('is-invalid').next('.form__error').remove();
        })

        var val = input.val().trim();
        if (val || input.hasClass('required')) {
          switch (input.attr('data-check')) {
            case 'name':
              if (/[0-9]/.test(val)) {
                error = true;
              }
              break;
            case 'number':
              if (/[^0-9]/.test(val)) {
                error = true;
              }
              break;
            case 'phone':
              if (!(/^[+]?[7-8][\s]*[(]?[0-9]{3}[)]?[\s]*[0-9]{3}[-]?[0-9]{2}[-]?[0-9]{2}$/.test(val) || /^[+]?[0-9]{3}[\s]*[(]?[0-9]{2}[)]?[\s]*[0-9]{3}[-]?[0-9]{2}[-]?[0-9]{2}$/.test(val))) {
                error = true;
              }
              break;
            case 'email':
              var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
              if (!pattern.test(val)) {
                error = true;
              }
              break;
          }
        }
        if (input.hasClass('required')) {
          switch (input.attr('type')) {
            default:
              var value = input.val();
              if (value.length == 0) {
                error = true;
              }
          }
        }
        if (error) {
          noErrors = false;
          input.addClass('is-invalid');
          input.parent().addClass('error-label form__label--error');
        }
      });

      if (!noErrors && !$('body').hasClass('no-overflow')) {
        var target = container.find('.is-invalid').first();
        if (target.length > 0) {
          $('html, body').animate({
            scrollTop: target.offset().top
          })
        }
      }
      if (noErrors) {
        //container.find('[data-submit]').removeClass('btn--disabled');
        return noErrors;
      } else {
        //container.find('[data-submit]').addClass('btn--disabled');
      }
    }
  } catch (error) {
    console.log(error)
  }



});