const getGallery = () => {
  const galleryButtons = document.querySelectorAll(".button-galary");
  const galleryMdal = document.querySelector("#albom");
  const galleryMdalContainer = document.querySelector(".albom-content");
  var galleryId = 0;

  galleryButtons.forEach((item, i) => {
    item.addEventListener("click", (e) => {
      e.preventDefault();
      galleryId = item.getAttribute("data-id");
      getGalleryItems(galleryId);
    });
  });

  function getGalleryItems(id) {
    let req = new XMLHttpRequest();
    req.responseType = "text";

    req.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        galleryMdalContainer.innerHTML = req.response
        $.fancybox.open({
          src: '#albom',
          type: 'inline'
        });
      }
    };
    req.open("GET", `/o-nas/foto-otchyotyi/albom.json?galAlbum=${id}`);
    req.send();
  }
};

export default getGallery;