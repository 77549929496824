const qa = () => {
  try {
    const qaItems = document.querySelectorAll('.client-qa__item')
    qaItems.forEach((item, i) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()

        if (!item.classList.contains('active')) {
          clearActive()
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    })

    const clearActive = () => {
      qaItems.forEach((item, i) => {
        if (item.classList.contains('active')) item.classList.remove('active')
      })
    }
  } catch (e) {}
}

export default qa;